import bg from '@laguna/resources/loading_bg.svg';
import { css } from '@mui/material';
import React from 'react';

const imageBackgroundCss = css`
  background: url(${bg});
  background-position: center;
  background-size: cover;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 0;
`;

export const Splash: React.FC = () => {
  return <div css={imageBackgroundCss} />;
};
