import { StrictMode, Suspense, lazy } from 'react';
import * as ReactDOM from 'react-dom/client';
import './favicon.png';
import './index.css';
import { Splash } from './Splash';

const LazyRoot = lazy(() => import('./Root'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <>
      <Splash />
      <Suspense fallback={<span />}>
        <LazyRoot />
      </Suspense>
    </>
  </StrictMode>
);
